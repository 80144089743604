import React, { useEffect } from 'react';
import {
	Grid,
	Box,
	makeStyles,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
	Container,
} from '@material-ui/core';
import '../home.css';
import './Homepage2.css';
import './Agents.css';
import mIamge from '../../assets/images/mImage.jpg';
import Footer from './Footer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MSearchFlight from '../../form/MSearch';
import Carousel from '../../components/reusableComponents/Carousels';
import {
	setSelectedOrigin,
	setSelectedDepart,
	setHotelToggle,
	toggleAPIFailedModal,
	toggleBPNRModal,
	toggleSessionExpiredModal,
} from '../../reducers/UiReducer';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import { setSection } from '../../reducers/UiReducer';
import CarouselBottom from '../../components/reusableComponents/CarouselBottom';
import CarouselInterHoliday from '../../components/reusableComponents/CarouselInterHoliday';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFooterSub_Menu,
	fetchFooterMenu,
	fetchTravelBlog,
	fetchPolularDestination,
	fetchHotel,
	fetchDeal,
	fetchAd,
	fetchlogo,
	fetchAdmin,
	fetchsociallink,
} from '../../reducers/HomeReducer';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchBusCityDispatch } from '../../reducers/BusReducer';
import { fetchHotelCityDispatch } from '../../reducers/HotelReducer';
import Meta from '../../components/reusableComponents/Meta';
import { fdSectorDispatch } from '../../reducers/FDReducer';
import APIFailedErrorModal from '../../components/reusableComponents/APIFailedErrorModal';
import SessionExpiredModal from '../../components/reusableComponents/SessionExpiredModal';
import { getbalanceDispatch } from '../../reducers/UatReducer';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		paddingRight: 15,
		paddingLeft: 15,
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	header: {
		background: '#fff',
		boxShadow: '0px 0px 10px rgb(0 0 0 / 5%)',
	},
	back: {
		// backgroundImage: `url(${mIamge})`,

		// backgroundPosition: '1px -241px',
		zIndex: 0,
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		transition: 'background-image 300ms ease-in 200ms',
	},
}));
const HomePage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const dt = new Date();
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { section } = useSelector((state) => state.ui);
	const { popularDesc, deal, ad, admin } = useSelector((state) => state.home);
	const { agentlogin } = useSelector((state) => state.user);
	window.onscroll = () => {
		let loginForm = document.querySelector('.login-form');
		let navbar = document.querySelector('.navbar');
		navbar.classList.remove('active');
		loginForm.classList.remove('active');
	};

	useEffect(() => {
		let form = {};
		dispatch(fetchFooterMenu(form));
		dispatch(fetchFooterSub_Menu(form));
		dispatch(fetchTravelBlog(form));
		dispatch(fetchHotelCityDispatch(form));
		dispatch(fetchBusCityDispatch(form));
		dispatch(fetchPolularDestination(form));
		dispatch(fetchAd(form));
		dispatch(toggleAPIFailedModal(false));
		dispatch(fetchAdmin(form));
		dispatch(fetchsociallink(form));
		dispatch(fetchHotel(form));
		dispatch(fetchlogo(form));
		dispatch(fetchDeal(form));
		dispatch(fdSectorDispatch(form));
		// dispatch(getbalanceDispatch(form));
		if (agentlogin && agentlogin.status === 200) {
			let logodata = {
				agent_id: agentlogin && agentlogin.result.result.id,
			};
			dispatch(agentlogogetDispatch(logodata));

			dispatch(
				walletBalanceAgentDispatch(
					agentlogin && agentlogin.result.result.email,
					agentlogin && agentlogin.result.result.id
				)
			);
		}
		if (location.pathname === '/') {
			dispatch(setSection('flight'));
			history.push('/Flights-Booking');
		} else if (location.pathname === '/bus-booking') {
			dispatch(setSection('bus'));
		} else if (location.pathname === '/hotel-booking') {
			dispatch(setSection('hotel'));
		}
		// dispatch(toggleBPNRModal(true));
	}, []);
	var d = new Date();
	console.log(d.toLocaleTimeString('en-GB'));

	return (
		<>
			{/* {section === 'flight' ? (
				<Meta
					title={'Mariners Forex | Flight'}
					description={
						'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.'
					}
					keywords={
						'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations'
					}
				/>
			) : section === 'bus' ? (
				<Meta
					title={'Mariners Forex | Bus'}
					description={
						'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.'
					}
					keywords={
						'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations'
					}
				/>
			) : section === 'hotel' ? (
				<Meta
					title={'Mariners Forex | Hotel'}
					description={
						'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.'
					}
					keywords={
						'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations'
					}
				/>
			) : (
				''
			)} */}
			<HeaderSection />

			<div style={{ position: 'relative' }}>
				<section
					onClick={() => {
						let loginForm = document.querySelector('.login-form');
						let navbar = document.querySelector('.navbar');
						navbar.classList.remove('active');
						loginForm.classList.remove('active');
					}}
					className={classes.back}
					style={{
						height: matches ? 620 : 350,
						backgroundImage: `url(${admin?.result[0]?.facebook})`,
					}}
					// data-aos='zoom-in'
				>
					<MSearchFlight />
					<Box display='flex' onClick={() => dispatch(setHotelToggle(false))}>
						{/* <div style={{ marginTop: 10, background: '#263a4a' }}>
							<p
								style={{
									color: 'red',
									fontSize: 16,
									fontWeight: 600,
									marginLeft: 5,
								}}>
								HotDeals
							</p>
						</div>
						<marquee>
							<div
								style={{
									marginTop: 10,
									background: '#263a4a',
									width: 'maxContent',
								}}>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
								<span style={{ marginLeft: 10, color: 'white' }}>
									E SINGAPORE AIRLINE @4200
								</span>
								<span style={{ marginLeft: 10, color: 'white' }}>|</span>
							</div>
						</marquee> */}
					</Box>
				</section>
			</div>

			<Box
				style={{ padding: 20 }}
				onClick={() => dispatch(setHotelToggle(false))}>
				<h3
					style={{
						color: '#0c2f54',

						fontFamily: '"Poppins", sans-serif',
					}}>
					Search & Book Hotels
				</h3>
				<span style={{ fontFamily: '"Poppins", sans-serif', fontSize: 14 }}>
					Enjoy unforgettable experiences in dream hotels worldwide
				</span>
				<div style={{ marginTop: 10 }}>
					<Carousel />
				</div>
			</Box>

			<Grid container>
				<Grid item xs={12} sm={6} lg={6}>
					<img
						src={deal && deal?.result[0].image}
						style={{ width: '90%', marginLeft: 20, height: '250px' }}></img>
				</Grid>
				<Grid item xs={12} sm={6} lg={6}>
					<img
						src={deal && deal?.result[1].image}
						style={{
							width: '90%',
							marginLeft: matches ? 20 : '',
							marginTop: matches ? 20 : '',
							height: '250px',
						}}></img>
				</Grid>
			</Grid>
			<div
				style={{
					padding: 20,
					boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
				}}>
				<h2
					style={{
						textAlign: 'center',
						fontWeight: 600,
						color: '#0c2f54',
						fontFamily: '"Poppins", sans-serif',
					}}>
					Start your travel planning here
				</h2>
				<Container style={{ marginTop: 20 }}>
					<Grid container spacing={2}>
						{popularDesc &&
							popularDesc?.result?.map((i, index) => {
								return (
									<Grid item xs={12} lg={4} alignItems='center'>
										<Box justifyContent='space-between' display='flex'>
											<h3>
												{i.destination.split(',')[0]}
												{/* {i.destination.split(',')[1].split('-')[0]} */}
											</h3>

											<ExpandMoreIcon />
										</Box>
										<Box>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: index === 0 ? 0 : 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.first
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.first.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.first.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.first.split(',')[0],
														country_name: i.first.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.first.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.second
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.second.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.second.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.second.split(',')[0],
														country_name: i.second.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.second.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.third
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.third.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.third.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.third.split(',')[0],
														country_name: i.third.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.third.split(',')[0]}
											</span>
											<span
												style={{
													cursor: 'pointer',
													color: 'black',
													marginLeft: 5,
												}}
												onClick={() => {
													let formData = {
														AdultCount: 1,
														ChildCount: 0,
														InfantCount: 0,
														DirectFlight: false,
														OneStopFlight: false,
														JourneyType: 1,
														PreferredAirlines: null,
														Segments: [
															{
																Origin: i.destination
																	.split(',')[1]
																	.split('-')[0],
																Destination: i.fourth
																	.split(',')[1]
																	.split('-')[0],
																FlightCabinClass: 1,
																PreferredDepartureTime: convert(
																	dt.setDate(dt.getDate() + 7)
																),
															},
														],
														Sources: 'WEB',
														agent_id: agentlogin
															? agentlogin.status === 200
																? agentlogin?.result.result.id
																: 0
															: 0,
													};
													history.push({
														pathname: `/oneway/${
															i.destination.split(',')[1].split('-')[0]
														}/${i.fourth.split(',')[1].split('-')[0]}/${convert(
															dt.setDate(dt.getDate() + 7)
														)}`,
														state: {
															body: formData,
															OriginO: convert(dt.setDate(dt.getDate() + 7)),
														},
													});
													let originData = {
														airport_code: i.destination
															.split(',')[1]
															.split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.destination.split(',')[0],
														country_name: i.destination
															.split(',')[1]
															.split('-')[1],
														country_code: 'IN',
													};
													let departData = {
														airport_code: i.fourth.split(',')[1].split('-')[0],
														airport_name: 'Chhatrapati Shivaji Intl.',
														city_name: i.fourth.split(',')[0],
														country_name: i.fourth.split(',')[1].split('-')[1],
														country_code: 'IN',
													};
													dispatch(setSelectedOrigin(originData));
													dispatch(setSelectedDepart(departData));
												}}>
												{i.fourth.split(',')[0]}
											</span>
										</Box>
										<Divider style={{ marginTop: 10 }} />
									</Grid>
								);
							})}
					</Grid>
				</Container>
			</div>

			<Container>
				{/* <a href={ad?.result[0]?.link} target='_blank'>
					<img
						src={ad?.result[0]?.image}
						style={{ width: '100%', height: 250 }}></img>
				</a> */}
				<h2 style={{ fontFamily: '"Poppins", sans-serif', color: '#0c2f54' }}>
					International Holidays
				</h2>
				<p style={{ marginBottom: 12 }}>
					Explore a variety of holiday tour packages and create lasting memories
				</p>
				<CarouselInterHoliday />
			</Container>
			<Container>
				{/* <a href={ad?.result[1]?.link} target='_blank'>
					<img
						src={ad?.result[1]?.image}
						style={{ width: '100%', height: 250 }}></img>
				</a> */}
			</Container>
			{/* <Hidden only={['xs']}>
				<Container>
					<h1
						style={{
							textAlign: 'center',
							fontWeight: 600,
							color: '#0c2f54',
							fontFamily: '"Poppins", sans-serif',
						}}>
						Travel Blog
					</h1>
					<p
						style={{
							textAlign: 'center',
							fontWeight: 600,
							color: '#0c2f54',
							fontFamily: '"Poppins", sans-serif',
						}}>
						Explore these places and some other thing here
					</p>
					<CarouselBottom />
				</Container>
			</Hidden> */}
			{/* <APIFailedErrorModal /> */}
			<Footer />
		</>
	);
};

export default HomePage;
